import { template as template_9806147eb2f7419cbc66d604e4860dfa } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ActivityCell = template_9806147eb2f7419cbc66d604e4860dfa(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="activity"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="activity"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ActivityCell;
